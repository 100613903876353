<template>
  <div class="container">
    <div class="row">
      <div class="placeholder-paragraph aligned-centered" v-if="loading">
        <div class="line"></div>
        <div class="line"></div>
      </div>

      <table class="table" v-if="!loading">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in users" v-bind:key="user.id">
          <td>
            {{ user.name }}
          </td>
          <td>
            {{ user.email }}
          </td>
          <td>
            <span style="font-size: 18px" v-on:click="impersonateUser(user)">
              <FeatherIcon icon="log-in"/>
            </span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import FeatherIcon from '~/components/FeatherIcon'

  export default {
    middleware: 'auth',
    components: {
      FeatherIcon
    },
    data: () => ({
      loading: true
    }),
    computed: mapGetters({
      users: 'admin/users',
    }),
    created() {
      this.$store.dispatch('admin/fetchUsers')
        .then(() => { this.loading = false; });
    },
    methods: {
      async impersonateUser(user) {   
        try{
          this.loading = true;

          const { data } = await axios.post('/api/v1/admin/impersonate/' +  user.id);
          
          // Save the information from the response
          this.$store.dispatch('auth/impersonate', {
            token: data.token
          });
          await this.$store.dispatch('auth/fetchUser');
          await this.$store.dispatch('account/fetchChurch');
          
          this.$router.push({ name: "accounts.profile" });
        } catch (e) {
          // this.$router.push({name: 'accounts.church.invite'});
        }
      }
    }
  }
</script>
